@import '../../../style/variables';

.latestvideopage-company-logo {
    height: 20px;
}

.latestvideopage-company-name {
    margin: 10px 0px 10px 0px;
    color: #003b4c;
    font-family: $font-family-body;
    font-size: 18px;
    font-weight: 300;
}

.latestvideopage-sector {
    margin: 20px 0px 5px 0px;
    color: #003b4c;
    font-family: $font-family-body;
    font-size: 24px;
    font-weight: 500;
}