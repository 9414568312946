.search-header {
    height: 60px;
    background-color: #001217;
    background-color: #001217;
}

.searchpage-text {
    color: #04222a;
    font-family: Yantramanav;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 15px;
}

.searchpage-button {
    color: #ffffff;
    font-family: Yantramanav;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 15px;
}

.searchpage-margin {
    padding-bottom: 95px;
}