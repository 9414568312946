@import '../../../style/variables';


.card-sector {
    height: 174px;
    margin-bottom: 10px;
}


.card-body:hover {
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, 0.69);
    box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, 0.69);

}


.card-body {
    background-color: #ECECEC;
    height: 174px;
    text-align: center;

    color: #003B4C;
    font-family: $font-family-body;
    font-size: 18px;
    font-weight: 400;
    //border: 2px #0f3945 solid;
    border-radius: 5px;

}

.card-body-sector {
    padding-top: 70px;
}

.card-body-event {
    padding-top: 50px;
}

