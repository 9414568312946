@import '../../../style/variables';

.companypage-header {
    margin-top: 25px;
}

.companypage-large {
    //padding: 0px 0px 15px 0px;
    //width: 100% !important;
    //height: 100% !important;
    //cursor: pointer;
    max-height: 500px;
}

.companypage-company-logo {
    height: 80px;
}

.companypage-btn {
    width: 100%
    //width: 200px;
}
.companypage-company-name {
    margin: 10px 0px 10px 0px;
    color: #003b4c;
    font-family: $font-family-body;
    font-size: 22px;
    font-weight: 500;
}
.companypage-video-name {
    color: #003B4C;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
}

.companypage-player {
    background-color: #001217;
    padding-top: 65px;
    padding-bottom: 5px;
}

.companypage-slide {
    cursor: pointer;
    padding: 0px 0px 15px 0px;
}

.companypage-related {
    color: #003b4c;
    font-family: Raleway;
    font-size: 18px;
    line-height: 21px;
}

.videoplayerpage-company-logo-other {
    max-height: 30px;
}

.companypage-othervideo-card:hover {
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, 0.69);
    box-shadow: 5px 5px 20px -5px rgba(0, 0, 0, 0.69);

}

.companypage-othervideo-card {

    /*height: 174px;*/
    margin-bottom: 10px;

    text-align: center;

    padding: 3px;
    color: #003B4C;
    font-family: $font-family-body;
    font-size: 18px;
    font-weight: 500;
    border: 1px #dee2e6 solid;
    border-radius: 5px;

}

.companypage-info {
    color: #04222a;
    font-family: Yantramanav;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin: 10px 0px 10px 0px;
}

.companypage-like {
    border: none !important;
    cursor: pointer;
}

.companypage-like:active {
    border: 1px;
}

svg:hover #iconLike {
    fill: #00acdb;
}

.companypage-like-text {
    vertical-align: top;
    color: #29383c;
    font-family: Yantramanav;
    font-size: 18px;
}

.companypage-line {
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border: 1px solid #ececec;
    margin: 15px 0px 15px 0px;
}



.companypage-section-2 {
    margin: 10px 0px 10px 0px;
    color: #003b4c;
    font-family: $font-family-body;
    font-size: 16px;
    font-weight: 700;

}

.companypage-company-desc {
    color: #04222a;
    font-family: Yantramanav;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 15px;
}

.companypage-confirmation-hide {
    display: none;
}
