@import '../../../style/variables';

.img-back {
  border: 1px solid #ECECEC;
  //border-radius: 5px 5px 0 0;
  //background-color: rgba(216, 216, 216, 0.9);
}

.home-sector-link {
  color: $color-primary;
  font-family: $font-family-h1;
  font-size: 20px;
  font-weight: 400;
}

//$home-text-bg: rgba(255, 255, 255, 0.9);
$home-text-bg: rgba(4, 34, 42, 0.8);
.home-videodesc {
  //border: 1px solid #ECECEC;
  border-radius: 5px 5px 5px 5px;
  background-color: $home-text-bg;
  width: 97%;
  color: $color-font-default;
  font-size: 14px;
  font-weight: 200;
  margin: 0 0 0 5px;
  padding: 0 5px 0 5px;
  font-family: $font-family-body;
  position: absolute;
  bottom: 8px;
  //left: 25px;
}

.home-eventdesc {
  //border: 1px solid #ECECEC;
  //border-radius: 5px 5px 5px 5px;
  //background-color: $home-text-bg;
  color: $color-secondary;
  font-size: 16px;
  cursor: pointer;
  //margin: 0 0 0 5px;
  font-family: $font-family-body;
  position: absolute;
  top: 80px;
}

.home-eventdesc-sm {
  //border: 1px solid #ECECEC;
  //border-radius: 0 0 5px 5px;
  //background-color: $home-text-bg;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
  //width: 100%;
  color: $color-secondary;
  font-size: 14px;
  //padding: 0 5px 0 5px;
  //margin: 0 0 0 5px;
  font-family: $font-family-body;
  position: absolute;
  bottom: 10px;
  //left: 25px;
}

.home-videodesc-sm {
  //border: 1px solid #ECECEC;
  //border-radius: 0 0 5px 5px;
  background-color: $home-text-bg;
  border-radius: 5px 5px 5px 5px;
  //width: 100%;
  color: $color-font-default;
  font-size: 12px;
  font-weight: 200;
  padding: 0 5px 0 5px;
  margin: 0 0 0 5px;
  font-family: $font-family-body;
  position: absolute;
  width: 94%;
  bottom: 1px;
  //left: 25px;
}

.home-companylogo-sm {
  position: absolute;
  width: 60%;
  top: 10px;
  left: 10px;
  z-index: 9999;
}

.home-video {
  cursor: pointer;
}

.home-companyname {
  font-weight: 300;
}

.home-eventname {
  font-size: 18px;
  font-weight: 500;
}

.home-event-image {
  background-color: black;
}