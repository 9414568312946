@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400&family=Yantramanav:wght@100;300;400;500&display=swap');

@import './custom_bootstrap';
@import 'bootstrap/scss/bootstrap';
//@import './variables';


//html {
//position: relative;
//min-height: 100%;
//}

//body {
//font-family: $font-family-body;
//padding-bottom: 186px;
//}
a:hover {
    color: #0080A3;
}

body, .sticky-footer-wrapper {
    min-height: 100vh;
}

.pointer {
    cursor: pointer;
}
//.flex-fill {
//    flex: 1 1 auto;
//}
.col-centered {
    float: none;
    margin: 0 auto;
}

h1 {
    color: #000000;
    font-family: $font-family-h1;
    font-size: 24px;
    letter-spacing: 1px;
}

h2 {
    color: #4e585b;
    font-family: $font-family-h2;
    font-size: 18px;
    letter-spacing: 1px;
}

/* Do not show download button on media player */
video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}

.text-borderless,
.text-borderless:focus {
    border: none !important;
    border-color: transparent !important;
}

textarea:focus, input:focus {
    outline: none;
}

.input-underline {
    color: $color-success !important;
    font-family: Yantramanav;
    //font-size: 18px !important;
    height: auto !important;
    border-radius: 0;
    border: 0;
    border-color: $color-success;
    border-bottom: 1px solid $color-success !important;
    /* taken from form-control */
    //padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1;
    padding: 0 8px 0 8px !important;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    //-webkit-transition: border-color ease-in-out 0.15s,
    //-webkit-box-shadow ease-in-out 0.15s;
    //transition: border-color ease-in-out 0.15s,
    //-webkit-box-shadow ease-in-out 0.15s;
    //-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    //transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    //transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    //-webkit-box-shadow ease-in-out 0.15s;
}

.input-underline:focus {
    box-shadow: none;
}

//.btn-primary {
//    color: #ffffff;
//    background: #003b4c;
//    font-family: $font-family-body;
//    font-size: 18px;
//    font-weight: 400;
//    border-radius: 4px;
//    border: 0px;
//}

.btn-xlg {
    padding: 0.5rem 2.5rem;
    font-size: 1.25rem;
    line-height: 1.75;
    border-radius: 0.3rem;
}

//.btn-primary:active {
//    border-radius: 4px;
//    background-color: #001217 !important;
//}
//
//.btn-primary:focus {
//    border-radius: 4px;
//    background-color: #001217 !important;
//}

//.btn-primary:hover {
//    text-decoration: none;
//    color: #fff;
//    background: #001217;
//    box-shadow: 0 0 8px 0 rgba(118, 118, 118, 0.8);
//}
//
//.btn-primary-outline {
//    color: #0080a3;
//    background: #fff;
//    font-family: $font-family-body;
//    font-size: 14px;
//    font-weight: 400;
//    border-radius: 4px;
//    border: 1px solid #0080a3;
//}
.banner1 {
    background: linear-gradient(90deg, rgba(0, 18, 23, 0.9) 0%, rgba(0, 18, 23, 0) 100%), url("../images/banner1.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 350px;
    width: 100%;
    padding-top: 200px;
}

.banner2 {
    background: linear-gradient(90deg, rgba(0, 18, 23, 0.9) 0%, rgba(0, 18, 23, 0) 100%), url("../images/banner2.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 400px;
    width: 100%;
    padding-top: 200px;
}
.bannerPsych {
    background: url("../images/banner-psych.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 400px;
    width: 100%;
}
button a {
    color: #ffffff;
    font-family: $font-family-body;
    font-size: 18px;
    font-weight: 400;
}

button a:hover {
    text-decoration: none;
    color: #ffffff;
    font-family: $font-family-body;
    font-size: 18px;
    font-weight: 400;
}

.btn-primary {
    background: linear-gradient(180deg, #00B3E4 0%, #0080A3 100%);
}

.btn-primary:hover {
    //background: linear-gradient(180deg, #00B3E4 100%, #0080A3 0%);
    box-shadow: 0 0 8px 0 rgba(118, 118, 118, 0.8);
}

.btn-primary:focus {
    border: 1px solid #0080A3;
    background: linear-gradient(180deg, #00B3E4 0%, #0080A3 100%);
    box-shadow: 0 0 8px 0 rgba(0, 128, 163, 0.5);
}

.btn-primary:active {
    background: #0080A3;
    box-shadow: none;
    //color: #0080a3 !important;
    //background-color: #dddddd !important;
    //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary-outline:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.field-label {
    color: #4e585b;
    font-family: $font-family-body;
    font-size: 14px;
}

.field-error {
    color: $color-error;
    font-family: $font-family-body;
    font-size: 14px;
    font-weight: 300;
}



.text-section {
    color: $color-secondary;
    font-family: $font-family-h1;
    font-size: 28px;
    font-weight: 400;
}

.text-md {
    color: #04222a;
    font-family: $font-family-body;
    font-size: 18px;
}

.text-sm {
    color: #04222a;
    font-family: $font-family-body;
    font-size: 14px;
}

//.form-control {
//    color: #04222a;
//    font-family: $font-family-body;
//    font-size: 14px;
//    font-weight: 400;
//    line-height: 18px;
//}

.dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #003b4c;
}

/* ie flexbox fix */
.flex-ie-fix-100 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
}