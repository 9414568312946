@import '../../../style/variables';

    footer {
        //position: absolute;
        //left: 0;
        //bottom: 0;
        background-color: $color-secondary !important;
        //width: 100%;
    }

.footer-text {
    font-family: $font-family-body;
    font-weight: 200;
    color: #fff !important;
    font-size: small;
}

.footer-text a {
    color: #fff !important;
}

