.confirmation-container {
  border: 1px solid #05635f;
  border-radius: 4px;
  background-color: rgba(5, 99, 95, 0.1);
  padding: 15px 37px 15px 37px;
  text-align:center;
}
.confirmation-title {
  color: #05635f;
  font-family: Yantramanav;
  font-size: 18px;
  font-weight: 500;
}

.confirmation-description {
  color: #04222a;
  font-family: Yantramanav;
  font-size: 18px;
}
