.loginpage-image {
    background-image: url("https://dzniw0su9j7m4.cloudfront.net/asset/signin.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100vw;
    height: 100vh;

}

.loginpage-logo {
    height:65px;
}

