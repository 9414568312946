@import '../../../style/variables';
.sector-company {
    cursor: pointer;
}

@media (max-width: 576px) {
    .sector-company-text {
        color: #003B4C;
        font-family: $font-family-body;
        font-size: 16px;
    }
}

@media (min-width: 577px) {
    .sector-company-text {
        color: #003B4C;
        font-family: $font-family-body;
        font-size: 18px;
    }
}

.sectorpage-company-logo {
    max-height: 33px;
    max-width: 150px;
}
