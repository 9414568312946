$font-family-nav: 'Raleway', sans-serif;
$font-family-h1: 'Raleway', sans-serif;
$font-family-h2: 'Raleway', sans-serif;
$font-family-body: 'Yantramanav', sans-serif;


$color-body: #FFFFFF;
$color-dark: #000925;
$color-font-default: #ffffff;
$color-primary: #0080A3;
$color-secondary: #003B4C;
$color-tertiary: #0f2775;
$color-error: #BF1E31;
$color-caution: #c7511a;
$color-success: #05635F;
$color-info: #1D92D0;
$color-highlight: #000925;

$thumbnail-bg: rgba(216, 216, 216, 0.9);

$btn-font-size: 14px;
$btn-border-radius: 4px;
$btn-focus-box-shadow: 0 0 8px 0 rgba(0, 128, 163, 0.5);

//$btn-active-box-shadow: none;
