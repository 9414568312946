@import '../../../style/variables';

//.navbar-tv-solid {
//    background-color: #001217;
//}
@media (min-width: 993px) {
    .navbar {
        padding: 0px !important;
        background: linear-gradient(180deg, #003B4C 0%, rgba(0, 59, 76, 0.6) 59.1%, rgba(0, 59, 76, 0.25) 82.72%, rgba(0, 59, 76, 0) 100%);
    }
}

@media (max-width: 992px) {
    .navbar {
        padding: 0px !important;
        background: $color-secondary;
    }
}
//.nav-item {
//    font-size: 12px;
//}

//.navbar-margin {
//    padding-left: 70px;
//    padding-right: 70px;
//}

.navbar-search {
    position: relative;
}

.navbar-iconsearch {
    position: absolute;
    display: inline-block;
    top: 4px;
    left: 10px;
}

//.nav-item {
//    margin-top: 10px;
//}

.navbar-input {
    height: 30px;
    border: 1px solid #05635f;
    border-radius: 4px;
    color: #fff;
    background-color: rgba(236, 236, 236, 0.5);
    text-indent: 28px;
}

.navbar-input:focus {
    height: 30px;
    border: 1px solid #05635f;
    border-radius: 4px;
    color: #fff;
    background-color: rgba(236, 236, 236, 0.5);
    text-indent: 28px;
}

//.navbar-top-text {
//    color: #fff !important;
//    //font-family: $font-family-body;
//    font-size: 11px;
//    font-weight: 700;
//}
//
//.navbar-top-text-a:hover {
//    color: #fff !important;
//}
//
//.navbar-top-text-a {
//    color: #a8a8a8;
//}

//.navbar-text {
//    color: #a8a8a8 !important;
//    font-family: $font-family-body;
//    font-size: 13px;
//    font-weight: 700;
//}

//.navbar-text:hover {
//    color: #fff !important;
//    text-decoration: none;
//}

.navbar-dropdown {
    color: #000;
    //font-family: $font-family-body;
    font-size: 14px;
    font-weight: 300;
}

.navbar-social-icon {
    height: 100%;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 30px;
    margin-bottom: 5px;
}

.navbar-social-icon:hover {
    color: #fff;
    background-color: #46d4fe;
}
